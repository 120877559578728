import React from 'react'
import { Row, Col, Image, Space, Typography, List } from 'antd'

const GrantAccessComponent = () => {

    const data = [
        {
          title: 'Attract and retain clients',
        },
        {
          title: 'Online self-booking',
        },
        {
          title: 'Get trusted ratings and reviews',
        },
        {
          title: 'Ant Design Title 4',
        },
      ];

  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]} style={{alignItems:'center'}}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={14}>
                        <Typography.Title level={1} style={{marginTop:'0px'}}>
                            Grant access to employee and patient data to improve the efficiency of scheduling tasks, clinicians, personnel, and other resources.
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus dolore illo aperiam mollitia omnis accusamus. Architecto omnis maiores molestias voluptatibus dolor,
                        </Typography.Text>
                        <Typography.Text style={{display:"block"}}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus dolore illo aperiam mollitia omnis accusamus. Architecto omnis maiores
                        </Typography.Text>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:"0px"}}>
                                <Space>
                                    <Image src='/assets/check.png' width={'15px'} preview={false} />
                                    <Typography.Text>
                                        {
                                            item?.title
                                        }
                                    </Typography.Text>
                                </Space>
                            </List.Item>
                            )}
                        />
                    </Space>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center bordereffect-tr'>
                        <Image src='/assets/images/img-4a.png' width={'100%'} preview={false} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {GrantAccessComponent}