import React from 'react'
import { Col, Image, Row, Space, Typography, Card } from 'antd';
import Slider from 'react-slick';
import './index.css'

const TestimonialSlider = () => {

    const brandtext = [
        {
            desc:`By far the best experience I’ve had with a salon booking system in 20 years! It’s super user friendly and intuitive - their no-show and cancellation policy and text reminders have ensured we have not had one no-show since we opened a year and a half ago!`,
            authur:'Pamela B.',
            img:'user-1.jpg'
        },
        {
            desc:`By far the best experience I’ve had with a salon booking system in 20 years! It’s super user friendly and intuitive - their no-show and cancellation policy and text reminders have ensured we have not had one no-show since we opened a year and a half ago!`,
            authur:'Pamela B.',
            img:'user-2.jpg'
        },
        {
            desc:`By far the best experience I’ve had with a salon booking system in 20 years! It’s super user friendly and intuitive - their no-show and cancellation policy and text reminders have ensured we have not had one no-show since we opened a year and a half ago!`,
            authur:'Pamela B.',
            img:'user-3.jpg'
        },
        {
            desc:`By far the best experience I’ve had with a salon booking system in 20 years! It’s super user friendly and intuitive - their no-show and cancellation policy and text reminders have ensured we have not had one no-show since we opened a year and a half ago!`,
            authur:'Pamela B.',
            img:'user-4.jpg'
        },
        {
            desc:`By far the best experience I’ve had with a salon booking system in 20 years! It’s super user friendly and intuitive - their no-show and cancellation policy and text reminders have ensured we have not had one no-show since we opened a year and a half ago!`,
            authur:'Pamela B.',
            img:'user-3.jpg'
        },
    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0
            }
          }
        ]
      }

  return (
    <div className='justify-center bg-light-gray testimonial mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Slider {...settings}>
                        {
                            brandtext?.map((brd,b)=>
                            <Card className='h-100 center card-cs' key={b} >
                                <div className='userimg'>
                                    <img src={"/assets/images/"+brd?.img} alt="" />
                                </div>
                                <Space direction='vertical' className='width-100 text-center' size={15}>
                                    <Typography.Title level={4} className='m-0'>
                                        {
                                            brd?.authur
                                        }
                                    </Typography.Title>
                                    <Typography.Title level={5} className='text-center m-0'>{brd?.desc}</Typography.Title>
                                    <Space>
                                        <Image src='/assets/icons/y-star.png' width={'25px'} />
                                        <Image src='/assets/icons/y-star.png' width={'25px'} />
                                        <Image src='/assets/icons/y-star.png' width={'25px'} />
                                        <Image src='/assets/icons/y-star.png' width={'25px'} />
                                        <Image src='/assets/icons/y-star.png' width={'25px'} />
                                    </Space>
                                </Space>
                            </Card>
                            )
                        }
                    </Slider>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {TestimonialSlider}