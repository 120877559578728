import React from 'react'
import { Row, Col, Image, Space, Typography, List } from 'antd'

const OnlineBook = () => {

    const data = [
        {
          title: 'Attract and retain clients',
        },
        {
          title: 'Online self-booking',
        },
        {
          title: 'Get trusted ratings and reviews',
        },
        {
          title: 'Ant Design Title 4',
        },
      ];

  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center bordereffect-tl '>
                        <Image src='/assets/images/img-1a.png' width={'100%'} preview={false} />
                    </div>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={14}>
                        <Typography.Title level={5} className='text-grey m-0'>
                            ONLINE HELP
                        </Typography.Title>
                        <Typography.Title level={1} style={{marginTop:'0px'}}>
                            Help to simplify clinical processes with tools for managing patient care
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo corrupti doloremque magnam voluptatem, dolores voluptatibus quo sequi deleniti voluptate est nisi minus nam impedit harum nihil necessitatibus maiores distinctio amet.
                        </Typography.Text>
                        <Typography.Text style={{display:"block"}}>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo corrupti doloremque magnam voluptatem, dolores voluptatibus quo sequi deleniti voluptate est nisi minus nam impedit harum nihil necessitatibus maiores distinctio amet.
                        </Typography.Text>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:'0px'}}>
                                <Space>
                                    <Image src='/assets/check.png' width={'15px'}  preview={false}/>
                                    <Typography.Text>
                                        {
                                            item?.title
                                        }
                                    </Typography.Text>
                                </Space>
                            </List.Item>
                            )}
                        />
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {OnlineBook}