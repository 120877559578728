import React from 'react'
import { Col, Image, Row, Space, Typography } from 'antd';
import Slider from 'react-slick';
import './index.css'

const TextSlider = () => {

    const brandtext = [
        {
            desc:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            authur:'Pamela B., Salon owner, NYC'
        },
        {
            desc:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            authur:'Pamela B., Salon owner, NYC'
        },
        {
            desc:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            authur:'Pamela B., Salon owner, NYC'
        },
        {
            desc:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            authur:'Pamela B., Salon owner, NYC'
        },
        {
            desc:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            authur:'Pamela B., Salon owner, NYC'
        },
    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }

  return (
    <div className='justify-center bg-light-gray slact-dt'>
        <div className='width-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 6}} md={{span: 6}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' className='text-center width-100' size={10} >
                        <Image src='/assets/captera.png' width={'130px'}  />
                        <Space align='center' className='width-100' style={{justifyContent:"center"}}>
                            <Image src='/assets/star.svg' width={'20px'} preview={false} />
                            <Image src='/assets/star.svg' width={'20px'} preview={false} />
                            <Image src='/assets/star.svg' width={'20px'} preview={false} />
                            <Image src='/assets/star.svg' width={'20px'} preview={false} />
                            <Image src='/assets/star.svg' width={'20px'} preview={false} />
                        </Space>
                        <Typography.Text>
                            5/5 on Capterra
                        </Typography.Text>
                    </Space>
                </Col>
                <Col lg={{span: 18}} md={{span: 18}} xs={{span: 24}} sm={{span: 24}}>
                    <Slider {...settings}>
                        {
                            brandtext?.map((brd,b)=>
                                <div key={b}>
                                    <Typography.Title level={5} className='m-0' style={{fontWeight: '500'}}>
                                        {
                                            brd.desc
                                        }                                        
                                    </Typography.Title>
                                    <Typography.Title level={5}>
                                        {
                                            brd.authur
                                        }                                        
                                    </Typography.Title>
                                </div>
                            )
                        }
                    </Slider>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {TextSlider}