import React from 'react'
import { Row, Col, Image, Space, Typography, List } from 'antd'

const AutoMarket = () => {

    const data = [
        {
          title: 'Month and year that the Medication Administration Record represents.',
        },
        {
          title: 'Date order was given, and date and time medication was administered.',
        },
        {
          title: 'Initial of the person transcribing the order.',
        },
        {
          title: 'Initial of the person giving the medication.',
        },
      ];
  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={14}>
                        {/* <Typography.Title level={5} className='text-grey m-0'>
                            AUTOMATED MARKETING
                        </Typography.Title> */}
                        <Typography.Title level={1} style={{marginTop:'0px'}}>
                            Administering medication
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            Medication administration occurs when medical professionals give their patients the medication they're prescribed. It's recommended that they give the task their complete attention, and never leave medications unattended.
                        </Typography.Text>
                        <Typography.Title level={5} className='m-0'>
                            The following information is included on the eMAR:
                        </Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:"0px"}}>
                                <Space>
                                    <Image src='/assets/check.png' width={'15px'} preview={false} />
                                    <Typography.Text>
                                        {
                                            item?.title
                                        }
                                    </Typography.Text>
                                </Space>
                            </List.Item>
                            )}
                        />
                        <Typography.Text style={{display:"block"}}>
                            Hospice Care Software very carefully fulfills the above most important requirements electronically for Administering Medication.
                        </Typography.Text>
                    </Space>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center bordereffect-tr'>
                        <Image src='/assets/images/img-2a.png' width={'100%'} preview={false} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {AutoMarket}