import React from 'react'
import { AutoAlertComponent, AutoMarket, BusinessAnalytics, BusinessType, ContactUs, GeneralCustomizable, GrantAccessComponent, ImageGallery, MainBanner, MedicalTrack, OnlineBook, TestimonialSlider, TextSlider } from '../../components'

const HomePage = () => {
  return (
    <div>
      <MainBanner />
      <TextSlider />
      <OnlineBook />      
      <BusinessAnalytics />
      <AutoMarket />
      <AutoAlertComponent />
      <GeneralCustomizable />
      <MedicalTrack />      
      <GrantAccessComponent />
      <TestimonialSlider />
      {/* <ImageGallery /> */}
      {/* <BusinessType /> */}
      <ContactUs />

    </div>
  )
}

export {HomePage}