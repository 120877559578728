import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import  './index.css'
import {ArrowRightOutlined} from '@ant-design/icons';
import {Typography, Image} from  'antd'
import { ErpButton } from '../ErpButton';
const {Text}= Typography
class Navbar extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            IsNavShowing:false
        }
    }
    showLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];
        navbarSmall.setAttribute("class","repla-navbar-small secondary-bg animation repla-navbar-small-move")
        var navbar=document.getElementsByClassName("repla-navbar")[0];
        navbar.setAttribute('class',"repla-navbar secondary-bg repla-navbar-trans")
        this.setState({IsNavShowing:!this.state.IsNavShowing})
    }
    hideLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];
        navbarSmall.setAttribute("class","repla-navbar-small animation secondary-bg")
        var navbar=document.getElementsByClassName("repla-navbar")[0];
        navbar.setAttribute('class',"repla-navbar")
        this.setState({IsNavShowing:!this.state.IsNavShowing})
    }
    render()
    {
        return  (
            <>
           <div  className='repla-navbar-container' style={{position:'relative'}}>
            <div className='w-100'>
                <div className="repla-navbar-small bg-white">
                    <div className="repla-navbar-inner">
                        <div className='repla-navbar-left'><img src={'/assets/logo.png'} alt='' width={'100%'}/></div>
                        <div className="repla-navbar-right">
                            <div className="sp-cover" onClick={this.showLargeNav}>
                                <span className="sp sp-1 sp-1-click"></span>
                                <span className="sp sp-2 sp-2-click"></span>
                                <span className="sp sp-3 sp-3-click"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='repla-navbar'>
                <div className="repla-navbar-inner">
                    <div className='repla-navbar-left'>
                        <Image src='/assets/logo.png' width={'100%'} preview={false} className='one'/>
                        <img src='/assets/icons/close.png' preview={false} className='two' alt='' onClick={this.hideLargeNav}/>
                    </div>
                    <div className='width-93'>
                        <ul className="repla-navbar-middle" style={{height:'100%'}}>
                            <li className='items'>
                                <NavLink to="/" className="nav-item" >
                                    <div className='align-center'>
                                        <ArrowRightOutlined className="nav-arrow animation text-dark"/>
                                        <Text strong className='my-0 item-name'>Home</Text>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="/" className="nav-item" >
                                    <div className='align-center'>
                                        <ArrowRightOutlined className="nav-arrow animation text-dark"/>
                                        <Text strong className='my-0 item-name'>About</Text>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="/" className="nav-item" >
                                    <div className='align-center'>
                                        <ArrowRightOutlined className="nav-arrow animation text-dark"/>
                                        <Text strong className='my-0 item-name'>Services</Text>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="/payment" className="nav-item" >
                                    <div className='align-center'>
                                        <ArrowRightOutlined className="nav-arrow animation text-dark"/>
                                        <Text strong className='my-0 item-name'>Subscription</Text>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <ErpButton title='Join Us' to='/'/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
           </div>
            </>
        )
    }
    componentDidMount(state)
    {
        var navbar=document.getElementsByClassName("repla-navbar")[0];
        var navbarLeft=document.getElementsByClassName("repla-navbar-left")
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];

      
        const responsive=()=>
        {
           
        }
        responsive();
        window.addEventListener("resize",()=>{responsive(); scrol();})
        let upDown;
        function scrol()
                {
                    console.log(window.pageYOffset)
                    if(window.innerWidth>970)
                    {
                        if(window.pageYOffset>upDown)
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","repla-navbar animation bg-white active");
                        }
                        else if(window.pageYOffset<11)
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","repla-navbar")
                        }
                        else
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","repla-navbar animation bg-white active")
                        }
                    }
                    else
                    {
                        navbar.setAttribute("class","repla-navbar");
                        if(window.pageYOffset>upDown)
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","repla-navbar-small secondary-bg animation repla-navbar-small-move")
                        }
                        else if(window.pageYOffset<11)
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","repla-navbar-small")
                        }
                        else
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","repla-navbar-small secondary-bg animation")
                        }
                    }
                    upDown=window.pageYOffset
                   
                }  
                scrol();
                window.onscroll = function() {scrol()}
    }
}
export default Navbar;
