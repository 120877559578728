import React from 'react'
import { Row, Col, Image, Space, Typography } from 'antd'

const GeneralCustomizable = () => {


  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]} style={{alignItems:'center'}}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center bordereffect-tl'>
                        <Image src='/assets/images/img-3a.png' width={'100%'} preview={false} />
                    </div>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={14}>
                        {/* <Typography.Title level={5} className='text-grey m-0'>
                            AUTOMATED MARKETING
                        </Typography.Title> */}
                        <Typography.Title level={1} style={{marginTop:'0px'}}>
                            Generate customizable forms for a variety of documentation needs, including Medicaid waivers, consent forms, assessments.
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            The Hospice S/w helps to enroll in Medicare, download Medicare related forms from its vast library for various requirements of Medicaid and Medicare.
                        </Typography.Text>
                    </Space>
                </Col>
                
            </Row>
        </div>
    </div>
  )
}

export {GeneralCustomizable}