import React from 'react'
import { Row, Col, Space, Typography, Image } from 'antd'
import './index.css'
import { ErpButton } from '../ErpButton'

const AutoAlertComponent = () => {

  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]}  style={{justifyContent:"space-between",alignItems:"center"}}>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='flex-class'>
                        <Space direction='vertical' className='TextSpace w-50' size={10}>
                            <Typography.Title level={1}>
                                Automating alerts
                            </Typography.Title>
                            <Typography.Title level={5} className='m-0' style={{display:"block",marginBottom:'20px'}}>
                                An automated alert is sent via email, pager, etc., which indicates that a predefined event has occurred and that some action is needed.
                            </Typography.Title>
                            <Typography.Title level={5} className='mt-0' style={{display:"block",marginBottom:'20px'}}>
                                The benefits of automated operations are higher productivity, reliability, availability, increased performance, and reduced operating costs.
                            </Typography.Title>
                            <Space>
                                <ErpButton title='Chat with us' to='/'/>
                            </Space>
                        </Space>
                        <div className='w-50'>
                            <Image src='/assets/alertimg.jpg' preview={false} width={'100%'} height={'100%'} className='rounded-right'/>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {AutoAlertComponent}