import React from 'react';
import { Col, Form, Row, Space, Typography, Input, Button, Select, Image, Card, Upload} from 'antd'
import { Link } from 'react-router-dom'
import './index.css'
import { AudioRecorder } from 'react-audio-voice-recorder';

const { Dragger } = Upload;
const { Option } = Select;

const ContactUs = () => {

    const [form] = Form.useForm()

    const addAudioElement = (blob) => {
        form.setFieldsValue({...form.getFieldsValue(),voice:blob})        
    };

    



      const props = {
        name: 'file',
        multiple: false,
        action: '',
        onChange(info) {
            const { file } = info;
            if (file.status !== 'uploading') {
                console.log(file, info.fileList);
                form.setFieldsValue({...form.getFieldsValue(),file:file?.originFileObj})
              }
        },
      }
      

      


    const data = [
        {
            count: '1',
            desc:'Having received and processed your request, we will get back to you shortly to detail your project needs and sign an NDA to ensure the confidentiality of information.'
        },
        {
            count: '2',
            desc:' After examining requirements, our analysts and developers devise a project proposal with the scope of works, team size, time and cost estimates.'
        },
        {
            count: '3',
            desc:'We arrange a meeting with you to discuss the offer and come to an agreement.'
        },
        {
            count: '4',
            desc:'We sign a contract and start working on your project as quickly as possible.'
        },
    ]
  return (
    <div className='justify-center my-3'>
        <div className='width-93'>
            <Row gutter={[32,24]} style={{justifyContent:'space-between'}}>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={40}>
                        <Space direction='vertical' size={0}>
                            <Typography.Title level={1} className='secondary-color'>
                                Contact us!
                            </Typography.Title>
                            <Typography.Title level={5}>
                                <Link to={'/'} className='brand-color f-weight-normal'>Book a call</Link> or fill out the form below and we’ll get back to you once we’ve processed your request.
                            </Typography.Title>
                        </Space>
                        <Form layout="vertical"
                            form={form}
                            >
                                <Row gutter={[16,16]}>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="name" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your name',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                size='large'
                                                placeholder="Name" 
                                                className='border-0-bottom'
                                                value={form.getFieldValue("name") || ''}
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email',
                                            },
                                            ]}
                                        >
                                        <Input 
                                            size='large'
                                            placeholder="Email" 
                                            className='border-0-bottom'
                                            value={form.getFieldValue("email") || ''}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="phone" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your phone no',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                size='large'
                                                placeholder="Phone" 
                                                className='border-0-bottom'
                                                value={form.getFieldValue("phone") || ''} 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="subject"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your subject',
                                            },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Subject"
                                                allowClear
                                                className='select-cs'
                                                value={form.getFieldValue("subject") || ''}
                                            >
                                                <Option value="it staff augmentation">IT staff augmentation</Option>
                                                <Option value="turnkey product development">Turnkey product development</Option>
                                                <Option value="support and ehancement">Support and enhancement</Option>
                                                <Option value="careers">Careers</Option>
                                                <Option value="other">Other</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your detail description',
                                            },
                                            ]}
                                        >
                                        <Input.TextArea
                                            rows={4}
                                            size='large'
                                            placeholder="Describe your need in detail" 
                                            className='border-0-bottom'
                                            value={form.getFieldValue("message") || ''} 
                                            />
                                        </Form.Item>
                                        <Typography.Text>
                                            Please include project details, duration, tech stack, IT professionals needed, and other relevant info
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                    <Form.Item name='voice'>
                                        <div className='block-border'>
                                            <Typography.Text>
                                                Record a voice message about your project to help us understand it better
                                            </Typography.Text>
                                            <div className="justify-center">
                                                <AudioRecorder 
                                                    onRecordingComplete={addAudioElement}
                                                    audioTrackConstraints={{
                                                        noiseSuppression: true,
                                                        echoCancellation: true,
                                                    }} 
                                                    downloadOnSavePress={false}
                                                    downloadFileExtension="webm"
                                                />
                                            </div>
                                        </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='file'>
                                            <div className='block-border'>
                                                <Typography.Text>
                                                    Upload a file / doc about your project to help us understand it better
                                                </Typography.Text>
                                                <Dragger {...props} className="dragcs">
                                                    <p className="ant-upload-drag-icon">
                                                        <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                    </p>
                                                    <p className="ant-upload-text">Upload profile picture</p>
                                                </Dragger>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24,24]} className='justify-end py-2'>
                                    <Col lg={{span: 18}} md={{span: 16}} xs={{span: 24}} sm={{span: 24}}>
                                        <Typography.Text>
                                            Please be informed that when you click the Send button Innowise will process your personal data in accordance with our Privacy Policy for the purpose of providing you with appropriate information.
                                        </Typography.Text>
                                    </Col>
                                    <Col lg={{span: 6}} md={{span: 8}} xs={{span: 24}} sm={{span: 24}}>
                                        <Button 
                                            htmlType='submit' 
                                            size='large' 
                                            type='primary'
                                            className='brand-bg brand-border w-100'
                                        >
                                            Send
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                    </Space>
                </Col>
                <Col lg={{span: 11}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Card className='card-block'>
                        <Space size={40} direction='vertical'>
                            <Typography.Title level={1} className='mt-5'>What happens next?</Typography.Title>
                            <div className='list-circle'>
                                <ul>
                                    {
                                        data?.map((datlst,ds)=>
                                        <li key={ds}>
                                            <div className='circle-count'>{datlst?.count}</div>
                                            {
                                                datlst?.desc
                                            }
                                        </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {ContactUs}