import React from 'react'
import { Row, Col, Space, Typography, Card } from 'antd'
import './index.css'
import { ErpButton } from '../ErpButton'

const MedicalTrack = () => {
  const data = {
    title:'Medicare tracking',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea fugit laboriosam consequuntur rem veniam molestiae ipsum officia a doloremque in quisquam nulla voluptates minima, suscipit vitae dolore earum asperiores. Dolores!'
  }
  return (
    <div className='justify-center mt-5 bg-img'>
        <div className='width-93'>
            <Row gutter={[24,24]} style={{alignItems:'center'}}>
                <Col lg={{span: 10}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Card className='card-round'>
                      <Space direction='vertical' className='TextSpace' size={10}>
                        <Typography.Title level={1} className='m-0'>
                            {
                              data?.title
                            }
                        </Typography.Title>
                        <Typography.Text style={{display:"block",marginBottom:'20px'}}>
                            {
                              data?.desc
                            }
                        </Typography.Text>
                        <Space>
                            <ErpButton title='Chat with us' to='/'/>
                        </Space>
                      </Space>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {MedicalTrack}