import React from 'react'
import { Row, Col, Space, Typography, Image } from 'antd'
import CountUp from "react-countup";

const BusinessAnalytics = () => {

    const data = {
        title:'The top-rated destination for beauty and wellness',
        desc:'One solution, one software. Trusted by the best in the beauty and wellness industry',
        analytics:[
            {
                title:'100',
                subtitle:'+',
                desc:'partner businesses',
                img: 'partner.png',
            },
            {
                title:'450',
                subtitle:'+',
                desc:'stylists and professionals',
                img: 'professional.png',
            },
            {
                title:'700',
                subtitle: '+ million',
                desc:'appointments booked',
                img: 'appointment.png',
            },
            {
                title:'120',
                subtitle: '+ countries',
                desc:'with Fresha available',
                img: 'available.png',
            },
        ]
    }
  return (
    <div className='justify-center bg-light-gray mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]} style={{justifyContent:"space-between",alignItems:"center"}}>
                <Col lg={{span: 8}} md={{span: 10}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={10}>
                        <Typography.Title level={1} className='m-0'>
                            {
                                data?.title
                            }
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            {
                                data?.desc
                            }
                        </Typography.Text>
                    </Space>
                </Col>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Row gutter={[32,32]}>
                        {
                            data?.analytics?.map((dat,d)=>
                                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}} key={d}>
                                    <Space direction='vertical' size={4}>
                                        <Image src={'assets/icons/'+dat?.img} preview={false} style={{display:"block"}} width={64} />
                                        <Typography.Title level={1} className='m-0 text-blue'>
                                            <CountUp duration={10} end={dat?.title}  />
                                            {
                                                dat?.subtitle
                                            }
                                        </Typography.Title>
                                        <Typography.Text className='text-grey' style={{display:"block"}}>
                                            {
                                                dat?.desc
                                            }
                                        </Typography.Text>
                                    </Space>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {BusinessAnalytics}