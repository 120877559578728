import React from 'react'
import { Col, Row, Space, Typography } from 'antd';
import { ErpButton } from '../ErpButton';
import './index.css';

const MainBanner = () => {

    const data = 
        {
            title1:"Best Hospice Software",
            title2:"Our Hospice Care Software qualifies and provides adequate management to Hospice Team",
            desc:"Hospice software helps manage clinical, financial, and administrative data for hospice agencies, allowing hospice staff to dedicate more time toward patient care. Hospices must strike a balance software captures and consolidates administrative data, and stores records and analytics centrally. This helps managers and administrators access the data in a few clicks."
        }
    
  return (
    <div className='justify-center banner-bg' style={{background:'url("/assets/main-banner.jpg")', height:'100vh', backgroundSize:'cover', backgroundAttachment:"fixed"}}>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'} style={{height:'100%'}}>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={15} className='width-100 text-center' >
                        <Typography.Title level={1} className='m-0 text-white'>
                            {
                                data?.title1
                            }
                        </Typography.Title>
                        <Typography.Title level={3} className='text-white m-0 text-center'>{data?.title2}</Typography.Title>
                        <Typography.Title level={4} className='text-white'>
                            {data?.desc}
                        </Typography.Title>
                        <Space>
                            <ErpButton title='Chat with us' to='/'/>
                        </Space>
                    </Space>
                </Col>
                {/* <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='imgframe'>
                        <Image src='/assets/images/Untitled-1.jpg' preview={false} />
                    </div>
                </Col> */}
            </Row>
        </div>
    </div>
  )
}

export {MainBanner}